<script setup lang="ts">
import { useKeenSlider } from 'keen-slider/vue';
import { CityAttrs } from '~~/utils/models/City';

defineProps<{ cities: CityAttrs[] }>();

const [keenSlider, keen] = useKeenSlider({
  slides: { origin: 'center', perView: 'auto', spacing: 16 },
  breakpoints: {
    '(min-width: 640px)': {
      slides: { perView: 'auto', spacing: 16 },
    },
    '(min-width: 1024px)': {
      slides: { perView: 'auto', spacing: 40 },
    },
  },
});
</script>

<template>
  <section class="overflow-hidden">
    <slot name="before" />

    <div class="container">
      <div ref="keenSlider" class="keen-slider !overflow-visible">
        <div
          class="keen-slider__slide min-w-[264px] lg:min-w-[408px]"
          :class="{ 'pt-16 lg:pt-48': i % 2 }"
          v-for="(city, i) of cities"
          :key="city.id"
        >
          <CityItem :city="city" :lazyImage="i > 0" seeDetails />
        </div>
      </div>
    </div>

    <slot name="after" />
  </section>
</template>
